import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Cleave from 'cleave.js/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import Numeral from 'numeral';
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from 'react-windowed-select';

export class FormArticle extends Component {
    constructor() {
        super();
        this.state = {
            liste_article: [],
            lprod: [],
            liste_sous_type_article: [],
            selectedArticle: null,
            idArticle: '',
            idArticle_dest: null,
            libArticle: '',
            codeArticle: '',
            cbArticle: '',
            descArticle: '',
            couvArticle: 'nophoto.png',
            uniteArticle: { label: 'Qauntité', value: '-' },
            remiseArticle: 0,
            finRemiseArticle: null,
            utilisationArticle: '',
            debutRemiseArticle: null,
            tvaArticle: 0,
            prixRefAchat: 0,
            constRefArticle: 1,
            prixRefVente: 0,
            prixRefRevient: 0,
            typePiPers: null,
            capturePiPers: '',
            couvArticle: '',
            adrcouvArticle: '',
            captureEngPers: '',
            liste_unite: [
                { label: 'Qauntité', value: '-' },
                { label: 'Pièce', value: 'Pce' },
                { label: 'Kilometre', value: 'Km' },
                { label: 'Kilometre carré', value: 'Km2' },
                { label: 'Hectar', value: 'ha' },
                { label: 'Metre', value: 'm' },
                { label: 'Metre carré', value: 'm2' },
                { label: 'Kilogramme', value: 'Kg' },
                { label: 'Gramme', value: 'g' },
                { label: 'Litre', value: 'l' },
                { label: 'Jour', value: 'j' },
                { label: 'Heure', value: 'h' },
                { label: 'Pacquet', value: 'p' },
                { label: 'Boite', value: 'b' },
                { label: 'NA', value: '.' },
            ],
            file: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_mvt: false,
            showindicator: false,
            idStype: null,
            formatArticle: 'NA',
            poidsArticle: 0,
            specArticle: 'NA',
            libType: 'NA',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.articleItemTemplate = this.articleItemTemplate.bind(this);
        this.edit_article = this.edit_article.bind(this);
        this.conf_delete_article = this.conf_delete_article.bind(this);
        this.show_dlg_mvt = this.show_dlg_mvt.bind(this);
        this.enreg_article = this.enreg_article.bind(this);
        this.onChange = this.onChange.bind(this)
        this.xulaservice = new Xulaservice();
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    articleItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="p-clearfix">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-5">
                            <label>{option.label}</label>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label>{option.cbArt}</label>
                        </div>
                        {this.state.userconnected.profilUser === 'PDG' ?
                            <div style={{ fontWeight: 'bold' }} className="p-col-12 p-md-2">
                                <label>{option.prix_ref_achat_format}</label>
                            </div>
                            : null}
                        <div className="p-col-12 p-md-2">
                            <label>{option.prix_ref_vente_format}</label>
                        </div>
                        <div className="p-col-12 p-md-1" >
                            <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{option.qte_stock_format}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }
    fileUpload(file, namefile) {
        this.xulaservice.upload_file(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    componentDidMount() {
        this.get_liste_sous_type_article();
        this.get_liste_article();
    }

    get_liste_sous_type_article() {
        this.xulaservice.get_liste_sous_type_article('Tous').then(data => this.setState({ liste_sous_type_article: data }));
    }

    get_liste_article() {
        this.setState({ showindicator: true });
        let lprod = null;


        this.xulaservice.get_liste_article_light().then(data => {
            this.setState({ liste_article: data, showindicator: false }, () => {
                if (this.state.liste_article != null && this.state.liste_article != undefined) {
                    lprod = this.state.liste_article.map(x => {
                        return { value: x.idArticle, label: x.libArticle, cbArt: x.cbArticle, qte_stock_format: 0, prix_ref_vente_format: this.formaterMontant(x.prixRefVente), prix_ref_vente: x.prixRefVente, qte_stock: 0, prix_ref_achat: x.prixRefAchat, prix_ref_achat_format: this.formaterMontant(x.prixRefAchat), idstype: x.idStype.idStype, libsoustypearticle: x.idStype.libStype, currentRemise: x.remiseArticle, label_rech: x.libArticle + ' - ' + x.idArticle }
                    });
                    this.setState({ lprod: lprod });
                }
            });
        });

        /*  this.xulaservice.get_inventaire_mins('Tous', '-1').then(data => {
             this.setState({ liste_article: data, showindicator: false }, () => {
                 if (this.state.liste_article != null && this.state.liste_article != undefined) {
                     lprod = this.state.liste_article.map(x => {
                         return { value: x.idArt, label: x.libArt, cbArt: x.cbArt, qte_stock_format: this.formaterMontant(x.qte_stock + x.qte_a_livrer), prix_ref_vente_format: this.formaterMontant(x.prix_ref_vente), prix_ref_vente: x.prix_ref_vente, qte_stock: x.qte_stock + x.qte_a_livrer, prix_ref_achat: x.prix_ref_achat, prix_ref_achat_format: this.formaterMontant(x.prix_ref_achat), idstype: x.idstype, libsoustypearticle: x.libsoustypearticle, currentRemise: x.currentRemise, label_rech: x.libArt + ' - ' + x.idArt }
                     });
                     this.setState({ lprod: lprod });
                 }
             });
         }); */
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_article();
            this.get_liste_article();
            this.forceUpdate();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_article(data) {
        if (data != null && data != undefined) {
            let unite = this.state.liste_unite.filter(function (el) {
                return el.value == data.uniteArticle;
            })[0];
            this.setState({ prixRefRevient: data.prixRefRevient });
            this.setState({ idArticle: data.idArticle });
            this.setState({ libArticle: data.libArticle });
            this.setState({ constRefArticle: data.constRefArticle });
            this.setState({ codeArticle: data.codeArticle });
            this.setState({ cbArticle: data.cbArticle });
            this.setState({ descArticle: data.descArticle });
            this.setState({ uniteArticle: unite });
            this.setState({ remiseArticle: data.remiseArticle });
            this.setState({ idStype: { value: data.idStype.idStype, label: data.idStype.libStype, libType: data.idStype.idType.libType } });
            this.setState({ finRemiseArticle: data.finRemiseArticle });
            this.setState({ debutRemiseArticle: data.debutRemiseArticle });
            this.setState({ tvaArticle: data.tvaArticle });
            this.setState({ prixRefAchat: data.prixRefAchat });
            this.setState({ prixRefVente: data.prixRefVente });
            this.setState({ couvArticle: data.couvArticle });

            this.setState({ specArticle: data.specArticle == null ? 'NA' : data.specArticle });
            this.setState({ formatArticle: data.formatArticle == null ? 'NA' : data.formatArticle });
            this.setState({ poidsArticle: data.poidsArticle });

            this.setState({ selectedArticle: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }

    annule_article() {
        this.setState({ idArticle: '' });
        this.setState({ libArticle: '' });
        this.setState({ codeArticle: '' });
        this.setState({ cbArticle: '' });
        this.setState({ descArticle: '' });
        this.setState({ remiseArticle: 0 });
        this.setState({ finRemiseArticle: '' });
        this.setState({ debutRemiseArticle: '' });
        this.setState({ prixRefRevient: 0 });
        this.setState({ tvaArticle: 0 });
        this.setState({ constRefArticle: 1 });
        this.setState({ prixRefVente: 0 });
        this.setState({ prixRefAchat: 0 });
        this.setState({ capturePiPers: 0 });
        this.setState({ poidsArticle: 0 });
        this.setState({ couvArticle: 'nocouv.png' });
        this.setState({ modif: false });
        this.setState({ file: null });
        this.forceUpdate();
    }

    enreg_article() {
        if (this.state.libArticle === "" || this.state.libArticle === undefined || this.state.idStype === "" || this.state.idStype === null || this.state.idStype === undefined || this.state.uniteArticle === "" || this.state.uniteArticle === undefined ||
            this.state.prixRefAchat === undefined || this.state.prixRefAchat === ''
            || this.state.prixRefRevient === undefined || this.state.prixRefRevient === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.couvArticle;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.userconnected.idUser + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            this.setState({ showindicator: true });
            if (this.state.modif === false) {
                let deb = moment(this.state.debutRemiseArticle, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let fin = moment(this.state.finRemiseArticle, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (deb === 'Invalid date') {
                    deb = null;
                }
                if (fin === 'Invalid date') {
                    fin = null;
                }

                let data = {
                    idArticle: '0',
                    libArticle: this.state.libArticle,
                    constRefArticle: this.state.constRefArticle,
                    prixRefRevient: this.state.prixRefRevient,
                    codeArticle: this.state.codeArticle,
                    cbArticle: this.state.cbArticle,
                    descArticle: this.state.descArticle,
                    uniteArticle: this.state.uniteArticle.value,
                    remiseArticle: this.state.remiseArticle,
                    finRemiseArticle: fin,
                    debutRemiseArticle: deb,
                    tvaArticle: this.state.tvaArticle,
                    prixRefAchat: this.state.prixRefAchat,
                    prixRefVente: this.state.prixRefVente,
                    couvArticle: nomfic,
                    idStype: { idStype: this.state.idStype.value },
                    formatArticle: this.state.formatArticle.value,
                    specArticle: this.state.specArticle.value,
                    poidsArticle: this.state.poidsArticle,
                }
                this.setState({ showindicator: true });
                this.xulaservice.enreg_article(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            } else {
                let deb = moment(this.state.debutRemiseArticle, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let fin = moment(this.state.finRemiseArticle, 'DD/MM/YYYY').format('DD/MM/YYYY');
                let data = this.state.selectedArticle;
                if (deb === 'Invalid date') {
                    deb = null;
                }
                if (fin === 'Invalid date') {
                    fin = null;
                }
                data.debutRemiseArticle = deb;
                data.finRemiseArticle = fin;
                data.constRefArticle = this.state.constRefArticle;
                data.couvArticle = nomfic;
                data.prixRefRevient = this.state.prixRefRevient;
                data.idArticle = this.state.selectedArticle.idArticle;
                data.libArticle = this.state.libArticle;
                data.codeArticle = this.state.codeArticle;
                data.cbArticle = this.state.cbArticle;
                data.uniteArticle = this.state.uniteArticle.value;
                data.prixRefAchat = this.state.prixRefAchat
                data.tvaArticle = this.state.tvaArticle;
                data.remiseArticle = this.state.remiseArticle;
                data.prixRefVente = this.state.prixRefVente;
                data.descArticle = this.state.descArticle;
                data.formatArticle = (this.state.formatArticle.value !== undefined) ? this.state.formatArticle.value : this.state.formatArticle;
                data.specArticle = (this.state.specArticle.value !== undefined) ? this.state.specArticle.value : this.state.specArticle;

                //data.formatArticle = (this.state.formatArticle!==null && this.state.formatArticle!==undefined)?this.state.formatArticle.value:'NA';
                //data.specArticle = (this.state.specArticle!==null && this.state.specArticle!==undefined)?this.state.specArticle.value:'NA';
                data.poidsArticle = this.state.poidsArticle;

                data.idStype = { idStype: this.state.idStype.value };
                this.setState({ showindicator: true });
                this.xulaservice.enreg_article(data)
                    .then(data => {
                        if (data != undefined && data != null) {
                            if (upload === true) {
                                this.fileUpload(this.state.file, nomfic);
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    });
            }
        }
    }

    mv_article() {
        this.setState({ showindicator: true });
        this.xulaservice.mv_article(this.state.selectedArticle.idArticle, this.state.idArticle_dest.value).then(data => {
            this.resultat(data.code, data.contenu);
            this.setState({ visible_dlg_mvt: false });
        });
    }

    actionTemplate(rowData) {
        let disabled = false;
        if (this.xulaservice.get_statut_offline() === 'true') {
            disabled = true;
        }
        return <div className="p-grid" style={{ width: 110 }}>
            <div className="p-col"><Button disabled={disabled} onClick={() => this.edit_article(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={() => this.show_dlg_mvt(rowData)} className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -12 }} icon="fa fa-fw fa-exchange" /></div>
            <div className="p-col"><Button disabled={disabled} onClick={() => this.conf_delete_article(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -12 }} icon="pi pi-times" /></div>
        </div>
    }


    photoTemplate(rowData, column) {
        return <img src={this.xulaservice.download_file(rowData.couvArticle, 'photos')} alt={rowData.libArticle} style={{ width: 50, height: 50 }} />
    }


    delete_article() {
        if (this.state.selectedArticle == null || this.state.selectedArticle == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            let val = { idArticle: this.state.selectedArticle.idArticle, libArticle: this.state.selectedArticle.libArticle };
            this.xulaservice.delete_article(val).then(data => this.resultat(data.code, data.contenu));
        }
    }

    show_dlg_mvt(rowData) {
        this.setState({ visible_dlg_mvt: true });
    }

    conf_delete_article(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedArticle: data });
            this.setState({ msg: data.libArticle });
            this.setState({ visible: true });
        }
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.enreg_article();
        }
    }

    render() {

        let lsous_type = null;
        if (this.state.liste_sous_type_article != null && this.state.liste_sous_type_article != undefined) {
            if (this.state.userconnected.matrPers.idBoutiq.typeBoutiq === 'Usine') {
                lsous_type = this.state.liste_sous_type_article.map(x => {
                    return { value: x.idStype, label: x.libStype, libType: x.idType.libType }
                }).filter(function (el) { return el.libType.includes("USINE") });
            } else {
                lsous_type = this.state.liste_sous_type_article.map(x => {
                    return { value: x.idStype, label: x.libStype, libType: x.idType.libType }
                });
            }

        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_article();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );



        const formatOptionLabel = ({ value, label, prix_ref_vente_format, prix_except, qte_stock_format }) => (
            <div className="p-grid">
                <div className="p-col-7">
                    <label>{label}</label>
                </div>
                <div className="p-col-1" >
                    <label style={{ fontWeight: 'bold', color: 'red', marginRight: '5px' }}>{qte_stock_format}</label>
                </div>
                {prix_except > 0 ?
                    <div style={{ fontWeight: 'bold' }} className="p-col-2">
                        <label>{this.formaterMontant(parseFloat(prix_except))}</label>
                    </div>
                    :
                    <div style={{ fontWeight: 'bold' }} className="p-col-2">
                        <label>{prix_ref_vente_format}</label>
                    </div>
                }
                <div className="p-col-2">
                    <label>{value}</label>
                </div>
            </div>
        );

        let color = '#0d8ced';
        let hovercolor = '#39a3f4';
        if (this.state.userconnected.idEtabl.domaineEtabl === 'Pharmacie') {
            color = '#4e805c';
            hovercolor = '#b4df98';
        } else if (this.state.userconnected.idEtabl.domaineEtabl === 'Station') {
            color = '#e10032';
            hovercolor = '#f8b4cb';
        }
        const selectStyles = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                width: 380,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 600,
            }),
        };

        const selectStyles_client = {
            control: (base, state) => ({
                ...base,
                height: 30,
                minHeight: 30,
                boxShadow: state.isFocused ? 0 : 0,
                borderColor: state.isFocused
                    ? color
                    : base.borderColor,
                '&:hover': {
                    borderColor: color
                }
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 15,
                minHeight: 15
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 5
            }),

            option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isDisabled
                    ? null
                    : state.isSelected
                        ? color
                        : state.isFocused
                            ? hovercolor
                            : null,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled && (state.isSelected ? color : color),
                },
                '&:hover': {
                    borderColor: color
                }
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 400,
            }),
        };

        const customFilter = createFilter({ ignoreAccents: false });

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Mouvement article" visible={this.state.visible_dlg_mvt} style={{ width: '30%' }} modal={false} onHide={(e) => this.setState({ visible_dlg_mvt: false })}>
                    <div className="p-grid" >
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ marginBottom: '-7px' }}>
                                {this.state.visible_dlg_mvt == true ?
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <span >
                                                <label style={{ fontSize: '13px', color: '#9A9C9C' }} htmlFor="inPu">Article à maintenir *</label>
                                            </span>
                                            <WindowedSelect id="new_dp_prod"
                                                formatOptionLabel={formatOptionLabel}
                                                isClearable={false}
                                                value={this.state.idArticle_dest}
                                                filterOption={customFilter}
                                                options={this.state.lprod}
                                                styles={selectStyles}
                                                placeholder="Articles"
                                                onChange={(value) => {
                                                    this.setState({ idArticle_dest: value })
                                                }}
                                            />
                                          
                                        </div>

                                    </div>
                                    : null}
                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => {
                                            this.setState({ visible_dlg_mvt: false });
                                        }} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.mv_article()} /></div>
                                        <div className="p-col">{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={false} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px' }}>
                            {this.xulaservice.get_statut_offline() === 'false' ?


                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="libArticle">Désignation *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText onKeyDown={this.handleKeyPress} id="libArticle" value={this.state.libArticle} onChange={(e) => this.setState({ libArticle: e.target.value, descArticle: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="codeArticle">Code </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText id="codeArticle" onKeyDown={this.handleKeyPress} value={this.state.codeArticle} onChange={(e) => this.setState({ codeArticle: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="idBoutiq">Sous type *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown value={this.state.idStype} options={lsous_type} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idStype: e.value }, () => {
                                            this.setState({ libType: this.state.idStype.libType });
                                        })} autoWidth={false} />
                                    </div>

                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="prixRefAchat">Prix d'achat de référence *</label>
                                        </div>
                                        : null}
                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-4">
                                            <Cleave onKeyDown={this.handleKeyPress} value={this.state.prixRefAchat} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefAchat: e.target.rawValue }, () => {
                                                let pv = Math.ceil(this.state.prixRefAchat * this.state.userconnected.idEtabl.commissionEtabl);
                                                this.setState({ prixRefVente: pv });
                                                this.setState({ prixRefRevient: this.state.prixRefAchat });
                                            })} className="p-inputtext" />
                                        </div>
                                        : null}

                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-2">
                                            <label htmlFor="cbArticle">Prix de revient de référence *</label>
                                        </div>
                                        : null}

                                    {this.state.userconnected.profilUser === 'PDG' ?
                                        <div className="p-col-12 p-md-4">
                                            <Cleave value={this.state.prixRefRevient} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefRevient: e.target.rawValue })} className="p-inputtext" />
                                        </div>
                                        : null}

                                    <div className="p-col-12 p-md-2">
                                        <label >Prix de vente de référence *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.prixRefVente} onKeyDown={this.handleKeyPress} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ prixRefVente: e.target.rawValue }, () => {
                                            if (this.state.userconnected.idEtabl.commissionEtabl !== 0) {
                                                let prix = 0;
                                                prix = Math.ceil(this.state.prixRefVente / this.state.userconnected.idEtabl.commissionEtabl);
                                                this.setState({ prixRefAchat: prix });
                                                this.setState({ prixRefRevient: prix });
                                            }
                                        })} className="p-inputtext" />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descArticle">{this.state.userconnected.idEtabl.domaineEtabl !== 'Imprimerie' ? 'Constante' : 'Spécification'} </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        {this.state.userconnected.idEtabl.domaineEtabl !== 'Imprimerie' ?
                                            <InputText placeholder="1 au minimum" onKeyDown={this.handleKeyPress} value={this.state.constRefArticle} onChange={(e) => this.setState({ constRefArticle: e.target.value })} />
                                            :
                                            <Dropdown autoWidth={false} editable options={[
                                                { value: 'NA', label: 'NA' },
                                                { value: 'Papier matt (Matt papers)', label: 'Papier matt (Matt papers)' },
                                                { value: 'Papier brillant (Glossy papers)', label: 'Papier brillant (Glossy papers)' },
                                                { value: 'Percale', label: 'Percale' },
                                                { value: 'Bache', label: 'Bache' },
                                                { value: 'Texte Recto', label: 'Texte Recto' },
                                                { value: 'Texte Recto-verso', label: 'Texte Recto-verso' },
                                                { value: 'Logo au dessus de la visière', label: 'Logo au dessus de la visière' },
                                                { value: 'Logo au dessus de la visière et sur les cotés', label: 'Logo au dessus de la visière et sur les cotés' },
                                                { value: 'Right side (Back passenger door)', label: 'Right side (Back passenger door)' },
                                                { value: 'One way vision sticker', label: 'One way vision sticker' },
                                                { value: 'Sticker back side to the driver’s door', label: 'Sticker back side to the driver’s door' },
                                                { value: 'For the back of the bus shelter', label: 'For the back of the bus shelter' },
                                            ]} value={this.state.specArticle} onChange={(e) => this.setState({ specArticle: e.value })} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                        }
                                    </div>

                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-2">
                                                <label htmlFor="cbArticle">Code barre </label>
                                            </div>
                                    }
                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-4">
                                                <InputText id="cbArticle" value={this.state.cbArticle} onChange={(e) => this.setState({ cbArticle: e.target.value })} />
                                            </div>
                                    }
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="photosous">Photo</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="uniteArticle">Unité *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown value={this.state.uniteArticle} options={this.state.liste_unite} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ uniteArticle: e.value })} autoWidth={false} />
                                    </div>

                                    {/* 
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="remiseArticle">Remise en % *</label>
                                    </div>
                                    <div className="p-col-6 p-md-4">
                                        <InputText keyfilter="pnumber" onKeyDown={this.handleKeyPress} value={this.state.remiseArticle} onChange={(e) => this.setState({ remiseArticle: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label >Date début remise *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar dateFormat="dd/mm/yy" value={this.state.debutRemiseArticle} onChange={e => this.setState({ debutRemiseArticle: new Intl.DateTimeFormat('en-GB').format(e.target.value) })}></Calendar>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label>Date fin remise *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Calendar dateFormat="dd/mm/yy" value={this.state.finRemiseArticle} onChange={e => this.setState({ finRemiseArticle: new Intl.DateTimeFormat('en-GB').format(e.target.value) })}></Calendar>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="tvaArticle">TVA *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText keyfilter="pnumber" onKeyDown={this.handleKeyPress} value={this.state.tvaArticle} onChange={(e) => this.setState({ tvaArticle: e.target.value })} />
                                    </div> */}

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Format *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown autoWidth={false} editable options={[
                                            { value: 'NA', label: 'NA' },
                                            { value: 'A1', label: 'A1' },
                                            { value: 'A2', label: 'A2' },
                                            { value: 'A3', label: 'A3' },
                                            { value: 'A4', label: 'A4' },
                                            { value: 'A5', label: 'A5' },
                                            { value: 'A6', label: 'A6' },
                                            { value: 'A7', label: 'A7' },
                                            { value: 'A8', label: 'A8' },
                                            { value: 'A9', label: 'A9' },
                                            { value: '1.20 x 1.80 m', label: '1.20 x 1.80 m' },
                                            { value: '3.65 x 1.50 m', label: '3.65 x 1.50 m' },
                                            { value: '3.65 x 0.49 m', label: '3.65 x 0.49 m' },
                                            { value: '1.36 x 0.75 m', label: '1.36 x 0.75 m' },
                                            { value: '0.83 x 0.79 m', label: '0.83 x 0.79 m' },
                                            { value: '5.13 x 1.5 m', label: '5.13 x 1.5 m' },
                                            { value: '5.13x 0.49 m', label: '5.13x 0.49 m' },
                                            { value: '1 x 1 m', label: '1 x 1 m' },
                                            { value: '1.49 x 1.03 m ', label: '1.49 x 1.03 m ' },
                                            { value: '0.76 x 1.03 m', label: '0.76 x 1.03 m' },
                                            { value: '1.5 x 1 m', label: '1.5 x 1 m' },
                                            { value: '23.82 x 3.10 m', label: '23.82 x 3.10 m' },
                                            { value: '8 x 2 m', label: '8 x 2 m' },
                                            { value: '6 x 1 m', label: '6 x 1 m' },
                                            { value: '6 x 2 m', label: '6 x 2 m' },
                                            { value: '4 x 1 m', label: '4 x 1 m' },
                                            { value: '4 x 2 m', label: '4 x 2 m' },
                                            { value: '3 x 1 m', label: '3 x 1 m' },
                                            { value: '3 x 2 m', label: '3 x 2 m' },
                                            { value: '2 x 1 m', label: '2 x 1 m' },
                                            { value: '2 x 2 m', label: '2 x 2 m' },
                                        ]} value={this.state.formatArticle} onChange={(e) => this.setState({ formatArticle: e.value })} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>
                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-2">
                                                <label htmlFor="inPu">Grammage  *</label>
                                            </div>
                                    }
                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-4">
                                                <Cleave value={this.state.poidsArticle} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ poidsArticle: e.target.rawValue })} className="p-inputtext" />
                                            </div>
                                    }
                                </div>
                                : null}

                            <center>
                                {this.xulaservice.get_statut_offline() === 'false' ?
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_article()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_article()} /></div>
                                    </div>
                                    : null}
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_article} paginatorPosition="top" paginator={true} rows={10}
                                onRowClick={e => this.setState({ selectedArticle: e.data })} selectionMode="single"
                                responsive={true} selection={this.state.selectedArticle} >
                                <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '9%' }} />
                                <Column field="idArticle" header="ID" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="libArticle" header="Désignation" filter={true} filterMatchMode='contains' sortable={true} />
                                {/* <Column field="codeArticle" header="Code" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} /> */}
                                <Column header="Photo" body={this.photoTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="prixRefAchat" body={(rowData, column) => {
                                    let prix_ref_achat = '*****';
                                    if (this.state.userconnected.profilUser === 'PDG') {
                                        prix_ref_achat = this.formaterMontant(parseFloat(rowData.prixRefAchat));
                                    }
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{prix_ref_achat}</span>
                                    </div>
                                }} header="Prix d'achat" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="prixRefVente" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(parseFloat(rowData.prixRefVente))}</span>
                                    </div>
                                }} header="Prix de vente" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="prixRefRevient" body={(rowData, column) => {
                                    let prix_ref = '*****';
                                    if (this.state.userconnected.profilUser === 'PDG') {
                                        prix_ref = this.formaterMontant(parseFloat(rowData.prixRefRevient));
                                    }
                                    return <div>
                                        <span style={{ color: '#000000', fontWeight: 'bold' }}>{prix_ref}</span>
                                    </div>
                                }} header="Prix de revient" style={{ width: '12%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}