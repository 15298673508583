import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import Cleave from 'cleave.js/react';
import Numeral from 'numeral';

export class FormParamPrix extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_sous_type_article: [],
            selectedData: null,
            poidsInfPprix: 0,
            poidsSupPprix: 0,
            qteInfPprix: 0,
            formatPprix: '',
            qteSupPprix: 0,
            newPprix: 0,
            idStype: null,
            rid_stype: 'Tous',
            nbPageSupPprix: 0,
            nbPageInfPprix: 0,
            specPprix: 'NA',
            libType: 'NA',
            coordonneePprix: '',
            msg: '',
            modif: false, 
            visible: false,
            showindicator: false ,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
        this.edit = this.edit.bind(this);
        this.conf_delete = this.conf_delete.bind(this);
        this.xulaservice = new Xulaservice();
    }

    formaterMontant(montant) {
        return Numeral(parseFloat(montant)).format('0,0');
    }

    componentDidMount() {
        this.get_liste_data();
        this.get_liste_sous_type_article();
    }

    get_liste_data() {
        this.setState({showindicator: true});
        this.xulaservice.get_liste_paramprix().then(data => this.setState({ liste_data: data,showindicator: false }));
    }

    get_liste_sous_type_article() {
        this.setState({showindicator: true});
        let liste = null;
        this.xulaservice.get_liste_sous_type_article('Tous').then(data => {
            this.setState({showindicator: false});
            if (data != null && data != undefined) {
                liste = data.map(x => {
                    return { value: x.idStype, label: x.libStype, libType: x.idType.libType }
                });
            }
            this.setState({ liste_sous_type_article: liste });

        });
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.growl.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule();
            this.get_liste_data();
        } else {
            this.growl.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }


    edit(data) {
        if (data != null && data != undefined) {
            this.setState({ poidsInfPprix: data.poidsInfPprix });
            this.setState({ poidsSupPprix: data.poidsSupPprix });
            this.setState({ qteInfPprix: data.qteInfPprix });
            this.setState({ qteSupPprix: data.qteSupPprix });
            this.setState({ newPprix: data.newPprix });
            this.setState({ idStype: { value: data.idStype.idStype, label: data.idStype.libStype,libType:  data.idStype.idType.libType } });
            this.setState({ nbPageSupPprix: data.nbPageSupPprix });
            this.setState({ nbPageInfPprix: data.nbPageInfPprix });
            this.setState({ formatPprix: data.formatPprix==null?'NA':data.formatPprix });
            this.setState({ 
                specPprix: data.specPprix==null?'NA':data.specPprix,
                coordonneePprix: data.coordonneePprix 
            });
            this.setState({ modif: true });
            this.setState({ selectedData: data });
        }
    }


    annule() {
        /*
        this.setState({ poidsSupPprix: 0 });
        this.setState({ poidsInfPprix: 0 });
        this.setState({ qteInfPprix: 0 });
        this.setState({ qteSupPprix: 0 });
        this.setState({ newPprix: 0 });
        this.setState({ nbPageSupPprix: 0 });
        this.setState({ nbPageInfPprix: 0 });
        */
        this.setState({ modif: false,coordonneePprix: '' });
        this.forceUpdate();
    }


    enregistrer() {
        if (this.state.poidsSupPprix === undefined || this.state.poidsSupPprix === null || this.state.poidsInfPprix === '') {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let data = { poidsInfPprix: this.state.poidsInfPprix,coordonneePprix: this.state.coordonneePprix, poidsSupPprix: this.state.poidsSupPprix, qteInfPprix: this.state.qteInfPprix, qteSupPprix: this.state.qteSupPprix, newPprix: this.state.newPprix, idPprix: '0', nbPageSupPprix: this.state.nbPageSupPprix, nbPageInfPprix: this.state.nbPageInfPprix, specPprix: this.state.specPprix.value, formatPprix: this.state.formatPprix.value, idStype: { idStype: this.state.idStype.value } };
                this.xulaservice.enreg_paramprix(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedData;
                data.poidsSupPprix = this.state.poidsSupPprix;
                data.poidsInfPprix = this.state.poidsInfPprix;
                data.qteInfPprix = this.state.qteInfPprix;
                data.qteSupPprix = this.state.qteSupPprix;
                data.newPprix = this.state.newPprix;
                data.nbPageSupPprix = this.state.nbPageSupPprix;
                data.nbPageInfPprix = this.state.nbPageInfPprix;
                data.specPprix = (this.state.specPprix.value!==undefined)?this.state.specPprix.value:this.state.specPprix;
                data.formatPprix = (this.state.formatPprix.value!==undefined)?this.state.formatPprix.value:this.state.formatPprix;
                data.coordonneePprix=this.state.coordonneePprix;
                this.xulaservice.enreg_paramprix(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -8 }} icon="pi pi-times" /></div>
        </div>
    }


    delete() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.growl.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.xulaservice.delete_paramprix(this.state.selectedData).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: '' });
            this.setState({ visible: true });
        }
    }



    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />

            </div>
        );

        return (
            <div className="p-fluid ">
                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>


                <div className="p-grid">

                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title" style={{ height: 550 }}>

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Sous type *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown autoWidth={false} options={this.state.liste_sous_type_article} value={this.state.idStype} onChange={(e) => {
                                            this.setState({ idStype: e.value, }, () => {
                                                this.setState({ libType: this.state.idStype.libType });
                                            })
                                        }
                                        } optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Format *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">

                                        <Dropdown inputId='dpFormat' autoWidth={false}
                                            editable optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'
                                            options={[
                                                { value: 'NA', label: 'NA' },
                                                { value: 'A1', label: 'A1' },
                                                { value: 'A2', label: 'A2' },
                                                { value: 'A3', label: 'A3' },
                                                { value: 'A4', label: 'A4' },
                                                { value: 'A5', label: 'A5' },
                                                { value: 'A6', label: 'A6' },
                                                { value: 'A7', label: 'A7' },
                                                { value: 'A8', label: 'A8' },
                                                { value: 'A9', label: 'A9' },
                                                { value: '1.20 x 1.80 m', label: '1.20 x 1.80 m' },
                                                { value: '3.65 x 1.50 m', label: '3.65 x 1.50 m' },
                                                { value: '3.65 x 0.49 m', label: '3.65 x 0.49 m' },
                                                { value: '1.36 x 0.75 m', label: '1.36 x 0.75 m' },
                                                { value: '0.83 x 0.79 m', label: '0.83 x 0.79 m' },
                                                { value: '5.13 x 1.5 m', label: '5.13 x 1.5 m' },
                                                { value: '5.13x 0.49 m', label: '5.13x 0.49 m' },
                                                { value: '1 x 1 m', label: '1 x 1 m' },
                                                { value: '1.49 x 1.03 m ', label: '1.49 x 1.03 m ' },
                                                { value: '0.76 x 1.03 m', label: '0.76 x 1.03 m' },
                                                { value: '1.5 x 1 m', label: '1.5 x 1 m' },
                                                { value: '23.82 x 3.10 m', label: '23.82 x 3.10 m' },
                                                { value: '8 x 2 m', label: '8 x 2 m' },
                                                { value: '6 x 1 m', label: '6 x 1 m' },
                                                { value: '6 x 2 m', label: '6 x 2 m' },
                                                { value: '4 x 1 m', label: '4 x 1 m' },
                                                { value: '4 x 2 m', label: '4 x 2 m' },
                                                { value: '3 x 1 m', label: '3 x 1 m' },
                                                { value: '3 x 2 m', label: '3 x 2 m' },
                                                { value: '2 x 1 m', label: '2 x 1 m' },
                                                { value: '2 x 2 m', label: '2 x 2 m' },
                                                
                                            ]} value={this.state.formatPprix} onChange={(e) => this.setState({ formatPprix: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Spécification *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Dropdown inputId='dpType' autoWidth={false} editable options={[
                                            { value: 'NA', label: 'NA' },
                                            { value: 'Papier matt (Matt papers)', label: 'Papier matt (Matt papers)' },
                                            { value: 'Papier brillant (Glossy papers)', label: 'Papier brillant (Glossy papers)' },
                                            { value: 'Percale', label: 'Percale' },
                                            { value: 'Bache', label: 'Bache' },
                                            { value: 'Texte Recto', label: 'Texte Recto' },
                                            { value: 'Texte Recto-verso', label: 'Texte Recto-verso' },
                                            { value: 'Logo au dessus de la visière', label: 'Logo au dessus de la visière' },
                                            { value: 'Logo au dessus de la visière et sur les cotés', label: 'Logo au dessus de la visière et sur les cotés' },
                                            { value: 'Right side (Back passenger door)', label: 'Right side (Back passenger door)' },
                                            { value: 'One way vision sticker', label: 'One way vision sticker' },
                                            { value: 'Sticker back side to the driver’s door', label: 'Sticker back side to the driver’s door' },
                                            { value: 'For the back of the bus shelter', label: 'For the back of the bus shelter' },
                                        ]} value={this.state.specPprix} onChange={(e) => this.setState({ specPprix: e.value })} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">{this.state.libType.toLowerCase().includes("serigraphie") ? 'Nombre de logo *' : 'Nombre de page *'}</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-grid" >
                                            <div className="p-col">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">
                                                        <label >De</label>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText value={this.state.nbPageInfPprix} onChange={(e) => this.setState({ nbPageInfPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="p-col">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">
                                                        <label >À </label>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText value={this.state.nbPageSupPprix} onChange={(e) => this.setState({ nbPageSupPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-2">
                                                <label htmlFor="inPu">Poids  *</label>
                                            </div>
                                    }
                                    {
                                        this.state.libType.toLowerCase().includes("serigraphie") ?
                                            null
                                            :
                                            <div className="p-col-12 p-md-4">
                                                <div className="p-grid" >
                                                    <div className="p-col">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                                <label >De</label>
                                                            </span>
                                                            <span className="p-float-label">
                                                                <InputText value={this.state.poidsInfPprix} onChange={(e) => this.setState({ poidsInfPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="p-col">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                                <label >À </label>
                                                            </span>
                                                            <span className="p-float-label">
                                                                <InputText value={this.state.poidsSupPprix} onChange={(e) => this.setState({ poidsSupPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }


                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Exemplaire *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-grid" >
                                            <div className="p-col">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">
                                                        <label >De</label>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText value={this.state.qteInfPprix} onChange={(e) => this.setState({ qteInfPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="p-col">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">
                                                        <label >À </label>
                                                    </span>
                                                    <span className="p-float-label">
                                                        <InputText value={this.state.qteSupPprix} onChange={(e) => this.setState({ qteSupPprix: e.target.value })} keyfilter="pnum" style={{ width: 150 }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Prix unitaire *</label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <Cleave value={this.state.newPprix} options={{ numeral: true, delimiter: ' ' }} onChange={(e) => this.setState({ newPprix: e.target.rawValue })} className="p-inputtext" />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="inPu">Coordonnées </label>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText value={this.state.coordonneePprix} onChange={(e) => this.setState({ coordonneePprix: e.target.value })}  />
                                    </div>
                                    

                                </div>

                                <center>
                                    <div className="p-grid" style={{ width: 300 }}>
                                        <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule()} /></div>
                                        <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enregistrer()} /></div>
                                    </div>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>

                                <Panel style={{ minHeight: '350px', marginBottom: 8 }}>
                                    <DataTable value={this.state.liste_data}
                                        responsive={true} scrollable={true} scrollHeight="300px" selectionMode="single"
                                        onRowClick={e => this.setState({ selectedData: e.data })}
                                        selection={this.state.selectedData}
                                    >
                                        <Column header="#" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                        <Column field="idStype.libStype" header="Sous type" sortable={true} filter={true} filterMatchMode='contains' />
                                        <Column field="specPprix" header="Spécification" style={{ width: '20%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                        <Column field="formatPprix" header="Format" style={{ width: '8%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                        <Column field="nbPageInfPprix" body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.nbPageInfPprix} - {rowData.nbPageSupPprix}</span>
                                            </div>
                                        }} header="Nb page" sortable={true} style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="poidsInfPprix" body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.poidsInfPprix} - {rowData.poidsSupPprix}</span>
                                            </div>
                                        }} header="Poids" sortable={true} style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="qteInfPprix" body={(rowData, column) => {
                                            return <div>
                                                <span >{rowData.qteInfPprix} - {rowData.qteSupPprix}</span>
                                            </div>
                                        }} header="Exemplaire" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="newPprix" header="Prix" body={(rowData, column) => {
                                            return <div>
                                                <span style={{ color: '#000000', fontWeight: 'bold' }}>{this.formaterMontant(rowData.newPprix)}</span>
                                            </div>
                                        }} sortable={true} style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="coordonneePprix" header="Coord." style={{ width: '8%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </Panel>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}